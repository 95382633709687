<template>
  <div
    v-if="
      $store.state.fastPermissoes.ativo == 'S' &&
        ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.professor == 'S')
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor')"
              >Modo professor</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Meus cursos</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Gerenciamento de
                  <span id="txtDashboardNomePlataforma">cursos</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->

              <section class="fd-app-meus-cursos">
                <div
                  class="fd-app-meus-cursos-filtros mb-4"
                  style
                >
                  <h3 class="aluno_font_color">
                    Filtrar Cursos
                  </h3>
                  <div class="fd-app-meus-cursos-filtros-form">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-5">
                        <div
                          class="easy-autocomplete eac-bootstrap position-relative"
                          style="width: 0px"
                        >
                          <Autocomplete
                            :get-label="getLabel"
                            :input-attrs="{
                              class: 'form-control',
                              placeholder: 'Nome do curso',
                            }"
                            :items="fastMeusCursosAutoComplete"
                            :component-item="CursosAutoComplete"
                            @update-items="updateItems"
                            @item-clicked="clickCurso"
                          />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-5 d-none">
                        <select class="form-control">
                          <option>Disciplina</option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-4 mt-4 d-none">
                        <select
                          id="selFiltroCursoSegmentos"
                          class="form-control"
                        >
                          <option value="0">
                            Segmento
                          </option>
                          <option value="1">
                            PREFEITURA E ÓRGÃOS PÚBLICOS
                          </option>
                          <option value="2">
                            INSTITUIÇÕES DE ENSINO
                          </option>
                          <option value="3">
                            INDÚSTRIA
                          </option>
                          <option value="4">
                            COMÉRCIO
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-3 d-none">
                        <select
                          id="selFiltroCursoAcesso"
                          class="form-control"
                        >
                          <option value="0">
                            Públicos e Restritos
                          </option>
                          <option value="P">
                            Somente públicos
                          </option>
                          <option value="R">
                            Somente restrito
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-4">
                        <select
                          id="selFiltroCursoPublicado"
                          v-model="filtroCursosPublicados"
                          class="form-control"
                          @change="filtraCursoPublicado()"
                        >
                          <option value="3">
                            Publicados e não publicados
                          </option>
                          <option value="1">
                            Somente publicados
                          </option>
                          <option value="0">
                            Somente não publicados
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-4 mt-4 d-none">
                        <select
                          id="selFiltroCursoLiberacao"
                          class="form-control"
                        >
                          <option value="0">
                            Liberados e não liberados
                          </option>
                          <option value="S">
                            Somente liberados automaticamente
                          </option>
                          <option value="N">
                            Somente não liberados
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-4 mt-4 d-none">
                        <select
                          id="selFiltroCursoPreco"
                          class="form-control"
                        >
                          <option value="0">
                            Com preço e sem preço
                          </option>
                          <option value="S">
                            Somente com preço
                          </option>
                          <option value="N">
                            Somente sem preço
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                        <a
                          v-if="
                            $store.state.fastPermissoes.administrador == 'S'
                          "
                          class="btn btn-primary btn-xs"
                          href="#"
                          @click.prevent="getFastCursos"
                        >Buscar cursos da plataforma</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fd-app-meus-cursos-list">
                  <div
                    id="divExibeFastCursos"
                    class="row"
                  >
                    <div class="col-12 text-center">
                      <h3
                        v-if="fastCursosDisponiveis"
                        class="aluno_font_color"
                      >
                        Cursos disponíveis
                        <span
                          class="total-meus-cursos"
                        >({{ fastMeusCursosAutoComplete.length }})</span>
                      </h3>
                      <h3
                        v-else
                        class="aluno_font_color"
                      >
                        Cursos da plataforma
                        <span
                          class="total-meus-cursos"
                        >({{ fastMeusCursosAutoComplete.length }})</span>
                      </h3>
                    </div>
                    <div
                      v-for="curso in pageOfItems"
                      :key="curso.id_curso"
                      class="col-sm-12 col-md-12 col-lg-4"
                    >
                      <div class="fd-app-meus-cursos-list-item">
                        <div
                          class="fd-app-meus-cursos-list-item-header"
                          style="min-height: 130px; position: relative"
                        >
                          <div
                            v-if="curso.publicado"
                            style="position: absolute; right: 0; top: 0"
                          >
                            <b-icon-check-circle-fill
                              font-scale="2"
                              variant="warning"
                            />
                          </div>
                          <h3 v-if="curso.nome_curso">{{ curso.nome_curso }}</h3>
                          <h3 v-else>{{ curso.nome_curso_mae }}</h3>

                          <p v-if="curso.detalhe_curso">{{ curso.detalhe_curso }}</p>
                          <p v-else>{{ curso.detalhe_curso_mae }}</p>
                        </div>
                        <div class="fd-app-meus-cursos-list-item-body">
                          <img
                            style="max-height: 170px"
                            :src="
                              curso.logo_curso
                                ? ajustaLinkApiAntiga(curso.logo_curso)
                                : imageSitePlataformaImg
                            "
                            class="img-fluid"
                          >
                          <div class="mt-4">
                            <div
                              class="row fd-app-meus-cursos-list-item-action"
                            >
                              <div
                                v-if="!fastCursosDisponiveis"
                                class="col-sm-12 col-md-12 col-lg-5 text-center mb-2"
                              >
                                <a
                                  class="btn btn-primary btn-md"
                                  :href="
                                    '/plataforma/' +
                                      $route.params.id_plataforma +
                                      '/professor/curso/' +
                                      curso.id_curso +
                                      '/aulas'
                                  "
                                >Aulas</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 text-center mt-4">
                      <Pagination
                        :page-size="9"
                        :items="fastMeusCursosAutoComplete"
                        @changePage="pageOfItems = $event"
                      />
                    </div>
                  </div>
                </div>
              </section>

              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' + $route.params.id_plataforma + '/professor'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
//import ImageSiteFormEffect01 from "@/assets/images/form-effect-01.png";
import imageSitePlataformaImg from "@/assets/images/app/plataforma-img.jpg";
import Pagination from "../components/Pagination";
import Autocomplete from "v-autocomplete";
import CursosAutoComplete from "../components/CursosAutoComplete";

export default {
  name: "HomeInternoProfessorMeusCursos",
  components: { Pagination, Autocomplete },
  mixins: [methods],
  data: function () {
    return {
      //ImageSiteFormEffect01,
      imageSitePlataformaImg,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      fastMeusCursos: [],
      fastMeusCursosAutoComplete: [],
      pageOfItems: [],
      CursosAutoComplete,
      Autocomplete,
      fastCursosDisponiveis: false,
      filtroCursosPublicados: 3
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'professor')
        .then(() => {
          this.getFastCursosProfessor();
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    // Autocomplete
    getLabel(item) {
      if (item.nome_curso) return item.nome_curso
      else
        return item.nome_curso_mae
    },
    // Autocomplete
    updateItems(text) {
      this.fastMeusCursosAutoComplete = this.fastMeusCursos.filter(
        (e) => {
          if (e.nome_curso) return e.nome_curso.toLowerCase().indexOf(text.toLowerCase()) != "-1"
          else
            return e.nome_curso_mae.toLowerCase().indexOf(text.toLowerCase()) != "-1"
        }
      );
    },
    // Autocomplete
    clickCurso(text) {
      this.fastMeusCursosAutoComplete = [];
      this.fastMeusCursosAutoComplete.push(text);
    },
    async getFastCursosProfessor() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_plataforma/lista_cursos_professor?&id_plataforma=" +
            this.retornaSessao(settings.fastSessaoPlataforma).id_plataforma +
            "&id_curso=",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let objPlataforma = Array.from(json);
        if (objPlataforma.length > 0) {
          //          console.log("getFastCursos", objPlataforma);
          this.fastCursosDisponiveis = false;
          this.fastMeusCursos = objPlataforma;
          this.fastMeusCursosAutoComplete = objPlataforma;
        }
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
      }
    },
    filtraCursoPublicado() {
        this.fastMeusCursosAutoComplete = this.fastMeusCursos;
      
        if (this.filtroCursosPublicados != "3") {
          this.fastMeusCursosAutoComplete = this.fastMeusCursosAutoComplete.filter((c) => {
          return c.publicado == this.filtroCursosPublicados;
        });
        }
        
        
      
    }
  },
};
</script>

<style></style>
