var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.$store.state.fastPermissoes.ativo == 'S' &&
      (_vm.$store.state.fastPermissoes.administrador == 'S' || _vm.$store.state.fastPermissoes.professor == 'S')
  )?_c('div',[_c('div',{staticClass:"fd-app-wrap"},[_c('div',{staticClass:"container"},[_c('nav',[_c('ul',{staticClass:"fd-app-breadcrumb"},[_c('li',{staticClass:"fd-app-breadcrumb-item"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":"/minhas-plataformas"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/minhas-plataformas')}}},[_vm._v("Minhas Plataformas")])]),_c('li',{staticClass:"fd-app-breadcrumb-item"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma)}}},[_vm._v("Plataforma "+_vm._s(_vm.$store.state.fastPlataforma.nome_plataforma))])]),_c('li',{staticClass:"fd-app-breadcrumb-item"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":'/plataforma/' + _vm.$route.params.id_plataforma + '/secretaria'},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma + '/professor')}}},[_vm._v("Modo professor")])]),_vm._m(0)])])]),_c('div',{staticClass:"fd-mode-adm-content mt-4"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0",attrs:{"id":"body_conteudo_modo_admin"}},[_c('section',{staticClass:"fd-app-welcome text-left"},[_vm._m(1),_c('img',{attrs:{"src":require('@/assets/images/form-effect-01.png')}})]),_c('section',{staticClass:"fd-app-meus-cursos"},[_c('div',{staticClass:"fd-app-meus-cursos-filtros mb-4"},[_c('h3',{staticClass:"aluno_font_color"},[_vm._v(" Filtrar Cursos ")]),_c('div',{staticClass:"fd-app-meus-cursos-filtros-form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-5"},[_c('div',{staticClass:"easy-autocomplete eac-bootstrap position-relative",staticStyle:{"width":"0px"}},[_c('Autocomplete',{attrs:{"get-label":_vm.getLabel,"input-attrs":{
                            class: 'form-control',
                            placeholder: 'Nome do curso',
                          },"items":_vm.fastMeusCursosAutoComplete,"component-item":_vm.CursosAutoComplete},on:{"update-items":_vm.updateItems,"item-clicked":_vm.clickCurso}})],1)]),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filtroCursosPublicados),expression:"filtroCursosPublicados"}],staticClass:"form-control",attrs:{"id":"selFiltroCursoPublicado"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.filtroCursosPublicados=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.filtraCursoPublicado()}]}},[_c('option',{attrs:{"value":"3"}},[_vm._v(" Publicados e não publicados ")]),_c('option',{attrs:{"value":"1"}},[_vm._v(" Somente publicados ")]),_c('option',{attrs:{"value":"0"}},[_vm._v(" Somente não publicados ")])])]),_vm._m(5),_vm._m(6),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4 mt-4"},[(
                          _vm.$store.state.fastPermissoes.administrador == 'S'
                        )?_c('a',{staticClass:"btn btn-primary btn-xs",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.getFastCursos.apply(null, arguments)}}},[_vm._v("Buscar cursos da plataforma")]):_vm._e()])])])]),_c('div',{staticClass:"fd-app-meus-cursos-list"},[_c('div',{staticClass:"row",attrs:{"id":"divExibeFastCursos"}},[_c('div',{staticClass:"col-12 text-center"},[(_vm.fastCursosDisponiveis)?_c('h3',{staticClass:"aluno_font_color"},[_vm._v(" Cursos disponíveis "),_c('span',{staticClass:"total-meus-cursos"},[_vm._v("("+_vm._s(_vm.fastMeusCursosAutoComplete.length)+")")])]):_c('h3',{staticClass:"aluno_font_color"},[_vm._v(" Cursos da plataforma "),_c('span',{staticClass:"total-meus-cursos"},[_vm._v("("+_vm._s(_vm.fastMeusCursosAutoComplete.length)+")")])])]),_vm._l((_vm.pageOfItems),function(curso){return _c('div',{key:curso.id_curso,staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('div',{staticClass:"fd-app-meus-cursos-list-item"},[_c('div',{staticClass:"fd-app-meus-cursos-list-item-header",staticStyle:{"min-height":"130px","position":"relative"}},[(curso.publicado)?_c('div',{staticStyle:{"position":"absolute","right":"0","top":"0"}},[_c('b-icon-check-circle-fill',{attrs:{"font-scale":"2","variant":"warning"}})],1):_vm._e(),(curso.nome_curso)?_c('h3',[_vm._v(_vm._s(curso.nome_curso))]):_c('h3',[_vm._v(_vm._s(curso.nome_curso_mae))]),(curso.detalhe_curso)?_c('p',[_vm._v(_vm._s(curso.detalhe_curso))]):_c('p',[_vm._v(_vm._s(curso.detalhe_curso_mae))])]),_c('div',{staticClass:"fd-app-meus-cursos-list-item-body"},[_c('img',{staticClass:"img-fluid",staticStyle:{"max-height":"170px"},attrs:{"src":curso.logo_curso
                              ? _vm.ajustaLinkApiAntiga(curso.logo_curso)
                              : _vm.imageSitePlataformaImg}}),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"row fd-app-meus-cursos-list-item-action"},[(!_vm.fastCursosDisponiveis)?_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-5 text-center mb-2"},[_c('a',{staticClass:"btn btn-primary btn-md",attrs:{"href":'/plataforma/' +
                                    _vm.$route.params.id_plataforma +
                                    '/professor/curso/' +
                                    curso.id_curso +
                                    '/aulas'}},[_vm._v("Aulas")])]):_vm._e()])])])])])}),_c('div',{staticClass:"col-12 text-center mt-4"},[_c('Pagination',{attrs:{"page-size":9,"items":_vm.fastMeusCursosAutoComplete},on:{"changePage":function($event){_vm.pageOfItems = $event}}})],1)],2)])]),_c('div',{staticClass:"mt-5 mb-5"},[_c('a',{staticClass:"btn btn-red-hollow",attrs:{"id":"btn_voltar_modo_admin","href":'/plataforma/' + _vm.$route.params.id_plataforma + '/professor'},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma + '/professor')}}},[_c('b-icon-arrow-return-left'),_vm._v(" Voltar ")],1)])])])])])]),_c('notifications',{attrs:{"group":"foo","position":"bottom right"}})],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"fd-app-breadcrumb-item active"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":"#"}},[_vm._v("Meus cursos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"aluno_font_color"},[_vm._v(" Gerenciamento de "),_c('span',{attrs:{"id":"txtDashboardNomePlataforma"}},[_vm._v("cursos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-5 d-none"},[_c('select',{staticClass:"form-control"},[_c('option',[_vm._v("Disciplina")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4 mt-4 d-none"},[_c('select',{staticClass:"form-control",attrs:{"id":"selFiltroCursoSegmentos"}},[_c('option',{attrs:{"value":"0"}},[_vm._v(" Segmento ")]),_c('option',{attrs:{"value":"1"}},[_vm._v(" PREFEITURA E ÓRGÃOS PÚBLICOS ")]),_c('option',{attrs:{"value":"2"}},[_vm._v(" INSTITUIÇÕES DE ENSINO ")]),_c('option',{attrs:{"value":"3"}},[_vm._v(" INDÚSTRIA ")]),_c('option',{attrs:{"value":"4"}},[_vm._v(" COMÉRCIO ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-3 d-none"},[_c('select',{staticClass:"form-control",attrs:{"id":"selFiltroCursoAcesso"}},[_c('option',{attrs:{"value":"0"}},[_vm._v(" Públicos e Restritos ")]),_c('option',{attrs:{"value":"P"}},[_vm._v(" Somente públicos ")]),_c('option',{attrs:{"value":"R"}},[_vm._v(" Somente restrito ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4 mt-4 d-none"},[_c('select',{staticClass:"form-control",attrs:{"id":"selFiltroCursoLiberacao"}},[_c('option',{attrs:{"value":"0"}},[_vm._v(" Liberados e não liberados ")]),_c('option',{attrs:{"value":"S"}},[_vm._v(" Somente liberados automaticamente ")]),_c('option',{attrs:{"value":"N"}},[_vm._v(" Somente não liberados ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4 mt-4 d-none"},[_c('select',{staticClass:"form-control",attrs:{"id":"selFiltroCursoPreco"}},[_c('option',{attrs:{"value":"0"}},[_vm._v(" Com preço e sem preço ")]),_c('option',{attrs:{"value":"S"}},[_vm._v(" Somente com preço ")]),_c('option',{attrs:{"value":"N"}},[_vm._v(" Somente sem preço ")])])])
}]

export { render, staticRenderFns }